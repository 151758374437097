import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogArticle = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2">
      <Link
        to={url}
        className="text-2xl text-lblue hover:text-indogo-600 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

const BlogArticles = ({ data }) => {
  const articles = data.allMarkdownRemark.edges;

  return (
    <Layout headerClass="bg-white relative">
    <SEO title="Blog"/>


    <div
      className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"

    >
      <div className="container w-1/2 md:w-1/3 mt-20 font-serif font-hairline self-start bg-gray-100"
      style={{
        boxShadow:
          "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
      }}
      >
        <h1 className="text-4xl md:text-5xl text-lblue px-2">
          Articles du blog
        </h1>
      </div>


      <div className="flex flex-wrap mt-10 md:mt-20  bg-gray-100">
        {articles.map(({ node }) => (
          <BlogArticle
            title={node.frontmatter.title}
            url={node.frontmatter.path}
            key={node.frontmatter.path}
            >
            {node.frontmatter.description}
            </BlogArticle>
          ))}
      </div>
    </div>
    </Layout>
  )
}

export const query = graphql`
query PostsQuery {
  allMarkdownRemark(
    filter: {
    frontmatter: {path: {regex: "/blog/"}}},
    sort: {order: DESC, fields: frontmatter___date}) {
      edges {
        node {
          frontmatter {
            path
            title
            description
          }
        }
      }
    }
  }
`;

export default BlogArticles
